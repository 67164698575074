import { useEffect } from "react"
import PropTypes from "prop-types"
import { useMap } from "react-leaflet"
import L from "leaflet"
import "leaflet-hotline"

const SpeedHeatmapPath = ({ mapPoints }) => {
  const map = useMap()
  useEffect(() => {
    if (!map) return

    L.hotline(
      mapPoints.map(p => [p.position.lat, p.position.lng, p.speed]),
      {
        weight: 3,
        palette: { 0: "green", 0.5: "yellow", 1: "red" },
        min: mapPoints.reduce(
          (total, point) => Math.min(total, point.speed),
          500
        ),
        max: mapPoints.reduce(
          (total, point) => Math.max(total, point.speed),
          0
        ),
      }
    ).addTo(map)
  }, [mapPoints, map])
  return null
}

SpeedHeatmapPath.propTypes = {
  mapPoints: PropTypes.array.isRequired,
}

export default SpeedHeatmapPath
