import * as React from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { MapContainer, TileLayer, Marker, Popup, Polyline } from "react-leaflet"
import SpeedHeatmapPath from "./speed-heatmap-path"

const groupPoints = (mapPoints, races) => {
  const out = {
    races: [],
    drifting: [],
  }
  if (mapPoints.length === 0) return out
  if (races.length === 0) return { races: [], drifting: [mapPoints] }

  let currentRaceIndex = 0
  let currentRace = races[currentRaceIndex]
  let currentList = []
  let state = "drifting"
  for (let i = 0; i < mapPoints.length; i++) {
    currentList.push(mapPoints[i])
    if (
      state === "drifting" &&
      currentRace !== null &&
      !moment(mapPoints[i].time).isBefore(currentRace.start)
    ) {
      out.drifting.push(currentList)
      currentList = [mapPoints[i]]
      state = "racing"
      continue
    }
    if (
      state === "racing" &&
      !moment(mapPoints[i].time).isBetween(currentRace.start, currentRace.end)
    ) {
      out.races.push(currentList)
      currentList = [mapPoints[i]]
      state = "drifting"
      currentRaceIndex = currentRaceIndex + 1
      currentRace = races[currentRaceIndex] ?? null
      continue
    }
  }
  if (state === "racing") {
    out.races.push(currentList)
  } else {
    out.drifting.push(currentList)
  }
  return out
}

const Map = ({ latitude, longitude, mapPoints, races }) => {
  if (typeof window !== "undefined") {
    const position = latitude && longitude ? [latitude, longitude] : null
    const centerMap = position
      ? position
      : mapPoints[Math.floor(mapPoints.length / 2)].position
    const lineGroups = groupPoints(mapPoints, races)
    return (
      <div className="map-container full-width">
        <MapContainer
          style={{ height: 400, width: "100%" }}
          center={centerMap}
          zoom={12}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=4d9060571b824402b68cb5e887987653"
          />
          {position && (
            <Marker position={position}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          )}
          {lineGroups.races.map(raceGroup => (
            <SpeedHeatmapPath key={raceGroup[0].time} mapPoints={raceGroup} />
          ))}
          {lineGroups.drifting.map(driftGroup => (
            <Polyline
              key={driftGroup[0].time}
              pathOptions={{ color: "grey", weight: 2, dashArray: 4 }}
              positions={driftGroup.map(node => [
                node.position.lat,
                node.position.lng,
              ])}
            />
          ))}
        </MapContainer>
      </div>
    )
  }
  return null
}

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
}

export default Map
